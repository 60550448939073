import React from 'react'
import whirl from '../SVGs/whirl.svg'

const Whirl = () => {
    return (
        <div>
            <img src={whirl} alt="" className='absolute -z-20 translate-y-[-50%]' />
        </div>
    )
}

export default Whirl
